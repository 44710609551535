<template>
  <v-row align="center" justify="center" no-gutters class="overflow-x-auto">
    <vue-headful
      title="Automotriz Petersen - Testimonios"
      description="En Petersen nos comprometemos a hacer nuestros los intereses del cliente. Sabemos lo importante y significativo que puede ser la compra o venta de un vehículo, es por eso que nuestra gestión se centra en asesorías honestas, confiables y transparentes hacia nuestros clientes. Valoramos y cultivamos un ambiente laboral en el que la colaboración, la integridad y el desarrollo personal impulsan el éxito colectivo basados en la confianza y el respeto."
    />
    <v-col cols="12">
      <v-row align="center" justify="center" no-gutters class="mt-6">
        <h1 class="info--text">#Lavozdenuestrosclientes</h1>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row align="center" justify="center" no-gutters class="mb-6">
        <h2 class="grey--text">Los testimonios de quienes nos eligen</h2>
      </v-row>
    </v-col>
    <v-row align="center" justify="center" no-gutters v-if="!records.length">
      <v-progress-circular indeterminate color="info" />
    </v-row>
    <div v-else class="masonry-container">
      <div
        class="masonry-item"
        v-for="(record, index) of records"
        :key="index"
        style="max-width: 350px"
      >
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="mx-auto my-2"
          style="max-width: 350px"
        >
          <div v-if="record.isComment">
            <testimony-comment :width="350" :data="record" />
          </div>
          <div v-else-if="record.isPhoto">
            <testimony-photo :width="350" :data="record" />
          </div>
          <div v-else>
            <testimony-video :data="record" />
          </div>
        </v-row>
      </div>
    </div>

    <v-col v-if="records.length" cols="12" class="my-6">
      <v-row align="center" justify="center" no-gutters>
        <v-btn color="info" @click="loadMore" class="white--text"
          >Cargar más
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import TestimonyComment from '@/components/testimony/testimonyComment.vue';
import TestimonyPhoto from '@/components/testimony/testimonyPhoto.vue';
import TestimonyVideo from '@/components/testimony/testimonyVideo.vue';

export default {
  components: {TestimonyVideo, TestimonyPhoto, TestimonyComment},
  data: () => ({
    records: [],
  }),
  async mounted() {
    this.GET_COMMENTS();
    this.GET_VID();
  },
  computed: {
    ...mapGetters(['getTestimonies']),
  },
  watch: {
    getTestimonies: {
      handler: function (val) {
        this.records = val.getNextBatch();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['GET_COMMENTS', 'GET_VID']),
    loadMore() {
      const newRecords = this.getTestimonies.getNextBatch();
      this.records = [...this.records, ...newRecords];
    },
  },
};
</script>

<style scoped>
.masonry-container {
  column-count: 5;
  column-gap: 16px;
}

.masonry-item {
  break-inside: avoid;
  margin-bottom: 16px;
  width: 100%;
}

@media (min-width: 1801px) {
  .masonry-container {
    column-count: 5;
  }
}

@media (min-width: 1441px) and (max-width: 1800px) {
  .masonry-container {
    column-count: 4;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .masonry-container {
    column-count: 3;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .masonry-container {
    column-count: 2;
  }
}

@media (max-width: 430px) {
  .masonry-container {
    column-count: 1;
  }
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
