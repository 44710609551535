<template>
  <v-card
    class="review-card"
    outlined
    :min-width="width"
    :max-width="width"
    elevation="2"
  >
    <v-row align="center" justify="space-around" no-gutters class="mt-2 mx-4">
      <v-col cols="12" class="pa-0 ma-0">
        <v-row align="center" justify="space-around" no-gutters>
          <v-col cols="2" class="pa-0 ma-0">
            <v-avatar color="info" size="40">
              <span class="white--text headline">{{ firstLetter }}</span>
            </v-avatar>
          </v-col>
          <v-col cols="10" class="pa-0 ma-0">
            <v-col cols="12" class="pa-0 ma-0">
              <p class="font-weight-bold mb-0 ml-1">{{ name }}</p>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
              <v-rating
                :value="rating"
                dense
                half-increments
                readonly
                color="yellow"
              ></v-rating>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="review-body">
      <div class="text-subtitle-1">Consejo</div>
      <div class="text-body-2">{{ comment }}</div>
    </div>
    <div class="review-footer">
      <div class="text-caption grey--text">{{ date }}</div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: Number,
      default: 350,
    },
  },
  computed: {
    firstLetter() {
      return this.data.testimony.name.trim().charAt(0);
    },
    name() {
      return this.data.testimony.name;
    },
    comment() {
      return this.data.testimony.comment;
    },
    rating() {
      return parseInt(this.data.testimony.rating);
    },
    date() {
      return this.data.testimony.createdTime;
    },
  },
};
</script>

<style scoped>
.review-card {
  max-width: 350px;
  border-radius: 4px;
  overflow: hidden;
}

.review-body {
  padding: 16px;
}

.review-footer {
  padding: 16px;
  text-align: right;
}
</style>
