<template>
  <v-img
    :max-width="width"
    :src="photo"
    :lazy-src="thumbnail"
    class="elevation-3 rounded"
  >
    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
        no-gutters
      >
        <v-progress-circular indeterminate color="info" />
      </v-row>
    </template>
  </v-img>
</template>

<script>
import {validateImageFull, validateImageSmall} from '@/utils/vehiculo';

export default {
  components: {},
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    width: {
      type: Number,
      default: 350,
    },
  },
  computed: {
    thumbnail() {
      return validateImageSmall(this.data.photo);
    },
    photo() {
      return validateImageFull(this.data.photo);
    },
  },
  watch: {},
  methods: {},
};
</script>
<style></style>
