<template>
  <video-component :source="data.video" :photo="data.photo" />
</template>

<script>
import VideoComponent from '@/components/vehiculo/videoComponent.vue';

export default {
  components: {VideoComponent},
  data: () => ({}),
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style></style>
